
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'FullBanners',
  props: {
    bannerfull: {
      type: Array,
      default() {}
    },
    delayTimer: {
      type: Object,
      default() {}
    }
  },
  data() {
    return {
      delay: 3000,
      itemRule: {
        title: '',
        text: ''
      },
      activeHover: true,
      swiperOption: {
        slidesPerView: 'auto',
        centeredSlides: true,
        spaceBetween: 100,
        mousewheel: false,
        autoplay: true,
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev'
        },
        pagination: {
          el: '.swiper-pagination',
          dynamicBullets: false,
          clickable: true
        }
      },
      imageP: '',
      imageG: ''
    }
  },
  computed: {
    ...mapGetters({
      rulesOpen: 'layout/GGrulesOpen'
    })
  },
  head () {
    if(this.imageG !== ''){
      return {
        link: [
          { rel: 'preload', as: 'image', href: this.imageP, async: true},
          { rel: 'preload', as: 'image', href: this.imageG, async: true}
        ]
      }
    }
  },
  async created() {
    this.preloadImages()
  },
  beforeMount() {
    window.addEventListener('resize', this.bannerDelay)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.bannerDelay)
  },
  mounted() {
    this.usePreloadedImages()
    this.bannerDelay()
  },
  methods: {
    ...mapActions({
      toggleRulesOpen: 'layout/AArulesOpen',
      titleRules: 'layout/AArulesTitle',
      textRules: 'layout/AArulesText'
    }),
    bannerDelay() {
      if (window.innerWidth <= 768) {
        this.delay = this.delayTimer.p
      }
      if (window.innerWidth >= 769 && window.innerWidth <= 992) {
        this.delay = this.delayTimer.m
      }
      if (window.innerWidth >= 993) {
        this.delay = this.delayTimer.g
      }
    },
    openRules(item) {
      this.titleRules(item.title)
      this.textRules(item.text)
      this.toggleRulesOpen(true)
    },
    async preloadImages() {
      for (const [index, item] of this.bannerfull.entries()) {
        if (index === 0 && item.g) { // Pré-carregue apenas a primeira imagem
          this.imageP = item.p
          this.imageG = item.g
          break
        }
      }
    },
    usePreloadedImages() {
      if(this.imageG !== ''){
        const imgP = new Image()
        imgP.src = this.imageP
        const imgG = new Image()
        imgG.src = this.imageG
      }
    }
  }
}
